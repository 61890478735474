import WKT from 'ol/format/WKT';

export const getFeatureByAnnotation = (annotation) => {
  const newFeature = new WKT().readFeature(annotation.location);
  return {
    geometry: {
      coordinates: newFeature.getGeometry().getCoordinates(),
      type: 'Polygon',
    },
    id: annotation.id,
    userId: annotation.user,
    properties: {
      annot: annotation,
      type: 'Feature',
    },
  };
};
