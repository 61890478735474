<template>
  <div
    ref="container"
    class="flex relative w-full h-full"
    @click="isActiveImage = true"
    @keydown="() => null"
  >
    <!-- For downloading the canvas -->
    <a
      ref="imageDownload"
      :download="image.instanceFilename + '.png'"
      style="display:none;"
    />
    <b-loading :is-full-page="true" :active.sync="isLoading" />
    <Progress
      v-if="loadingPercent > 0"
      :key="loadingPercent"
      :percent="loadingPercent"
      style="position:absolute; bottom:0px; width:150px;z-index:99999999;left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;"
    />

    <template v-if="loaded && zoom !== null">
      <vl-map
        ref="map"
        :data-projection="projectionName"
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        :keyboard-event-target="document"
        @pointermove="projectedMousePosition = $event.coordinate"
        @mounted="updateKeyboardInteractions"
      >
        <vl-view
          ref="view"
          :center.sync="center"
          :zoom.sync="zoom"
          :rotation.sync="rotation"
          :max-zoom="maxZoom"
          :max-resolution="Math.pow(2, image.depth)"
          :extent="extent"
          :projection="projectionName"
          data-label="View"
          :constrain-only-center="true"
          @mounted="viewMounted"
        />

        <vl-layer-tile
          ref="baseLayer"
          :extent="extent"
          @mounted="addOverviewMap"
        >
          <vl-source-zoomify
            ref="baseSource"
            :projection="projectionName"
            :urls="baseLayerURLs"
            :size="imageSize"
            :extent="extent"
            :transition="0"
            cross-origin="Anonymous"
            @mounted="setBaseSource"
          />
        </vl-layer-tile>

        <vl-layer-image>
          <vl-source-raster
            v-if="baseSource && colorManipulationOn"
            :sources="[baseSource]"
            :operation="operation"
            :lib="lib"
          />
        </vl-layer-image>

        <AnnotationLayer
          v-for="layer in selectedLayers"
          :key="'layer-' + layer.id"
          :heatmap-mode="heatmapMode"
          :index="index"
          :layer="layer"
          @setLoadingPercent="setLoadingPercent"
        />

        <BrushInteraction
          v-if="activeBrushInteraction"
          :index="index"
          :mouse-position="projectedMousePosition"
          :map="$refs.map"
          :viewer-width="viewerWidth"
        />

        <RectangleInteraction
          v-if="activeRectangleInteraction"
          :index="index"
          :mouse-position="projectedMousePosition"
          :map="$refs.map"
          :viewer-width="viewerWidth"
        />
        <SelectInteraction v-if="activeSelectInteraction" :index="index" />

        <DrawInteraction v-if="activeDrawInteraction" :index="index" />
        <ModifyInteraction v-if="activeModifyInteraction" :index="index" />

        <div class="ol-control top-72 left-8 z-100">
          <div v-for="mag in magification.slice().reverse()">
            <BTooltip
              :label="$t('need-magnification-to-use')"
              :active="!image.magnification"
              position="is-right"
            >
              <BButton
                :key="mag.value"
                class="mag-btn"
                :data-label="`${mag.value}MagBtn`"
                :disabled="!image.magnification"
                @click="zoom = calcZoom(mag.value)"
              >
                <span class="size-12">{{ mag.level }}</span>
              </BButton>
            </BTooltip>
          </div>
          <BButton
            class="mag-btn"
            data-label="ResetMagBtn"
            @click="resetToDefault()"
          >
            <i class="fas fa-expand" />
          </BButton>
        </div>
      </vl-map>

      <OntologyBar
        v-if="showOntologyBar"
        :index="index"
        :active.sync="showOntologyBar"
      />

      <IdxBtn
        v-if="
          !showOntologyBar &&
            isPanelDisplayed('ontology') &&
            terms &&
            terms.length
        "
        style="left:50%;right:50%;z-index:999999;"
        class="absolute bottom-8"
        link
        @click="showOntologyBar = true"
      >
        <i class="fas fa-chevron-up" />
      </IdxBtn>

      <DrawTools
        v-if="configUI['project-tools-main']"
        :index="index"
        class="absolute top-12 left-48 right-48 z-10"
        :viewer-width="viewerWidth"
      />

      <div id="image-navigation-buttons" class="ol-control">
        <button
          v-tooltip="$t('image-previous')"
          class="button"
          data-label="prev-image-btn"
          @click="previousImage"
        >
          <span class="icon is-small">‹</span>
        </button>
        <span class="image-index px-1">
          {{ imageIndex }} / {{ totalImages }}
        </span>
        <button
          v-tooltip="$t('image-next')"
          class="button"
          data-label="next-image-btn"
          @click="nextImage"
        >
          <span class="icon is-small">›</span>
        </button>
      </div>

      <div class="panels bg-gray-5">
        <ul class="grid">
          <li class="grid border-b border-gray-4">
            <button
              class="btn-plain px-3 py-2 color-red"
              data-label="CloseBtn"
              @click="$emit('close')"
            >
              <i class="fas fa-times-circle" />
              <span class="visually-hidden">{{ $t('close') }}</span>
            </button>
          </li>
          <li
            class="grid border-b border-gray-4"
            :class="{ 'updating-background': loadingPercent > 0 }"
          >
            <button
              class="btn-plain px-3 py-2 color-gray-1"
              data-label="ReloadBtn"
              @click="handleReloadAnnotations"
            >
              <i
                class="fas fa-sync-alt"
                :class="{ 'fa-spin': loadingPercent > 0 }"
              />
              <span class="visually-hidden">{{ $t('reload') }}</span>
            </button>
          </li>

          <template v-if="isPanelDisplayed('hide-tools')">
            <li
              v-for="panel in panels"
              :key="panel.key"
              :class="[
                { [panel.activeClass]: panel.isActive },
                'grid',
                'border-b',
                'relative',
                'border-gray-4',
              ]"
            >
              <button
                :class="[
                  'btn-plain block m-0 px-3 py-2 size-20 text-center',
                  {
                    'color-gray-1': activePanel !== panel.key,
                    'color-gray-5 hover:color-gray-5 bg-gray-1':
                      activePanel === panel.key,
                  },
                ]"
                :data-label="`${panel.key}Btn`"
                @click="togglePanel(panel.key)"
              >
                <BTag
                  v-if="getTagContent(panel.key)"
                  type="is-danger"
                  style="    position: absolute;
                  top: -2px;
                  left: -5px;
                  border-radius: 32px;
                  padding: 0.7em;
                  font-size: 0.5em;
                  transition-duration: 3s;"
                >
                  {{ getTagContent(panel.key) }}
                </BTag>
                <i :class="panel.icon" />
              </button>
              <component
                :is="panel.component"
                v-show="activePanel === panel.key"
                :index="index"
                style="right:100%"
                class="panel-options absolute z-100 w-400 overflow-auto p-3 bg-gray-1"
                v-bind="panel.params"
              />
            </li>
          </template>
        </ul>
      </div>

      <RenameModal
        :active.sync="showNamingModal"
        :title="$t('save-as')"
        :description="$t('channel-save-description')"
        @rename="saveChannelGroupToProjectAsNew"
      />
      <CalibrationModal
        :image="image"
        :active.sync="showCalibrationModal"
        @setResolution="setResolution"
      />
      <!-- <div
        v-if="imageWrapper.tracking.broadcast"
        class="absolute top-12 right-64 radius-4 px-2 py-1 uppercase bg-red color-white"
      >
        <i class="fas fa-circle" /> {{ $t('live') }}
      </div> -->

      <RotationSelector :index="index" class="absolute left-8" />

      <div class="ol-control absolute snapshot-container left-8">
        <IdxBtn v-tooltip="$t('take-screenshot')" @click="takeScreenshot">
          <span class="fas fa-camera" />
        </IdxBtn>
      </div>
      <div class="ol-control  absolute copy-link-container left-8">
        <IdxBtn v-tooltip="$t('copy-link-with-position')" @click="copyLink">
          <span class="fas fa-share-alt" />
        </IdxBtn>
      </div>

      <ScaleLine
        :image="image"
        :zoom="zoom"
        :mouse-position="projectedMousePosition"
        :padding-bottom="showOntologyBar ? 11 : 1"
      />

      <AnnotationDetailsContainer
        v-if="isPanelDisplayed('annotation-main')"
        :index="index"
        :view="$refs.view"
      />

      <AnnotationsDetailsContainer
        v-if="isPanelDisplayed('annotation-main')"
        :index="index"
        :view="$refs.view"
      />

      <div
        ref="overview"
        class="custom-overview absolute left-8 radius-4"
        :class="{ 'bottom-8': !showOntologyBar, 'bottom-7em': showOntologyBar }"
      >
        <p
          v-if="!overviewCollapsed"
          class="size-12 px-2 max-w-160"
          data-label="Overview"
        >
          <ImageName :image="image" />
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import View from 'ol/View';
import OverviewMap from 'ol/control/OverviewMap';
import interactions, {
  KeyboardPan,
  KeyboardZoom,
  DragPan,
  Draw,
  defaults as defaultInteractions,
} from 'ol/interaction';
import Vector from 'ol/source/Vector';
import { Style, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { getWidth } from 'ol/extent';
import { noModifierKeys, targetNotEditable } from 'ol/events/condition';
import WKT from 'ol/format/WKT';
import { addProjection as addOlProj } from 'ol/proj';
import { Tile } from 'ol/layer';
import {
  ImageConsultation,
  Annotation,
  AnnotationType,
  UserPosition,
} from 'cytomine-client';

import { addProj, createProj, getProj } from 'vuelayers/src/ol-ext/proj.js';
import { GetZoomifySource } from '../../utils/ol/zoomify-source.js';
import { DownloadMap } from '../../utils/ol/download-map.js';
import noteApi from '../../services/noteApi.js';
import RenameModal from '../utils/RenameModal.vue';
import AnnotationLayer from './AnnotationLayer.vue';
import RotationSelector from './RotationSelector.vue';
import ScaleLine from './ScaleLine.vue';
import DrawTools from './DrawTools.vue';

import InformationPanel from './panels/InformationPanel.vue';
import DigitalZoom from './panels/DigitalZoom.vue';
import ColorManipulation from './panels/ColorManipulation.vue';
import AssayPanel from './panels/AssayPanel.vue';
import LinkPanel from './panels/LinkPanel.vue';
import LayersPanel from './panels/LayersPanel.vue';
import OntologyPanel from './panels/OntologyPanel.vue';
import OntologyBar from './OntologyBar.vue';
import PropertiesPanel from './panels/PropertiesPanel.vue';
import FollowPanel from './panels/FollowPanel.vue';
import ReviewPanel from './panels/ReviewPanel.vue';
import ResultsPanel from './panels/ResultsPanel.vue';
import ChannelManipulation from './panels/ChannelManipulation.vue';
import HeatmapPanel from './panels/HeatmapPanel.vue';
import ClusteringPanel from './panels/ClusteringPanel.vue';

import AnnotationDetailsContainer from './AnnotationDetailsContainer.vue';
import AnnotationsDetailsContainer from './AnnotationsDetailsContainer.vue';

import RectangleInteraction from './interactions/RectangleInteraction.vue';
import BrushInteraction from './interactions/BrushInteraction.vue';
import SelectInteraction from './interactions/SelectInteraction.vue';
import DrawInteraction from './interactions/DrawInteraction.vue';
import ModifyInteraction from './interactions/ModifyInteraction.vue';
import CalibrationModal from './../image/CalibrationModal.vue';

import ImageName from './../image/ImageName.vue';
import constants from './../../utils/constants.js';
import { constLib, operation } from './../../utils/color-manipulation.js';
import Progress from '@/components/utils/Progress.vue';

function getCustomValue(string, index) {
  if (string != null) {
    const values = string.split('-');
    return parseInt(values[index]);
  }
  return null;
}
const MAX_ANNOTATIONS_TO_LOAD = 30000; // max number of annotations to load at certain zoom levels

export default {
  name: 'CytomineImage',
  components: {
    ImageName,
    Progress,

    AnnotationLayer,

    RotationSelector,
    ScaleLine,
    DrawTools,
    AnnotationsDetailsContainer,
    AnnotationDetailsContainer,
    OntologyBar,

    RectangleInteraction,
    BrushInteraction,
    SelectInteraction,
    DrawInteraction,
    ModifyInteraction,
    CalibrationModal,
    RenameModal,
    ChannelManipulation,
  },
  props: {
    index: { type: String, default: undefined },
    images: { type: Array, default: () => [] },
  },
  emits: ['copyLink'],
  data() {
    return {
      minZoom: 0,

      projectedMousePosition: [0, 0],

      baseSource: null,
      routedAnnotation: null,

      timeoutSavePosition: null,

      loaded: false,
      layerLoadingPercents: {},
      loadingPercent: 0,

      overview: null,

      magification: [
        { level: '5X', value: 5 },
        { level: '10X', value: 10 },
        { level: '20X', value: 20 },
        { level: '40X', value: 40 },
      ],
      showCalibrationModal: false,
      showOntologyBar: false,
      showNamingModal: false,
      viewerWidth: 0,
      defaultTermIds: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    document() {
      return document;
    },
    routedAction() {
      return this.$route.query.action;
    },
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    currentProject() {
      return this.$store.state.currentProject.project;
    },
    projectId() {
      return this.$store.state.currentProject.project.id;
    },
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    nbImages() {
      return Object.keys(this.viewerWrapper.images).length;
    },
    imageWrapper() {
      return this.viewerWrapper.images[this.index];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    channels() {
      return this.mapChannels(this.imageWrapper.channels.activeGroup.colorMaps);
    },
    mirroring() {
      return this.imageWrapper.draw.activeMirroring;
    },
    canEdit() {
      return this.$store.getters['currentProject/canEditImage'](this.image);
    },
    canManipulate() {
      return this.imageWrapper.channels.canManipulate;
    },
    projectionName() {
      return `CYTO-${this.image.id}`;
    },
    terms() {
      return this.$store.getters['currentProject/terms'];
    },
    /** @returns {object} */
    termIndexDictionary() {
      const dictByTermId = {};
      if (this.terms) {
        this.terms.forEach((term, idx) => (dictByTermId[term.id] = idx));
        return dictByTermId;
      } else {
        return [];
      }
    },
    viewerTerms() {
      return this.imageWrapper.style.terms;
    },
    selectedLayers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    /** @returns {boolean} */
    successfulCall() {
      return this.imageWrapper.channels.successfulCall;
    },
    heatmapMode() {
      return this.imageWrapper.view.heatmapMode;
    },
    clustering() {
      return this.imageWrapper.view.clustering;
    },
    viewerSettings() {
      return this.$store.getters['currentProject/viewerSettings'];
    },
    useCustomRectangleSize() {
      return this.viewerSettings.useCustomRectangleSize;
    },
    annotationCounts() {
      return this.$store.getters[this.imageModule + 'getAnnotationCounts'];
    },
    isActiveImage: {
      get() {
        return this.viewerWrapper.activeImage === this.index;
      },
      set(value) {
        if (value) {
          if (this.viewerWrapper) {
            this.$store.commit(
              this.viewerModule + 'setActiveImage',
              this.index
            );
          }
        } else {
          throw new Error('Cannot unset active map');
        }
      },
    },
    isLoading() {
      return this.viewerWrapper.isLoading;
    },
    /**
     * @returns {Array<{
     * key: string
     * component: import('vue').Component
     * label: string | import('vue-i18n').TranslateResult
     * icon: string
      }>} */
    panels() {
      const { nbImages, terms, canEdit } = this;
      const panels = [
        {
          isDisplayed: this.currentUser.admin && !this.currentProject.isClosed,
          key: 'assay-panel',
          component: AssayPanel,
          label: this.$t('algorithm-launch'),
          icon: 'fas fa-rocket',
        },
        {
          isDisplayed: this.canManipulate,
          key: 'channels',
          component: ChannelManipulation,
          label: this.$t('channels'),
          icon: 'fas fa-sliders-h',
        },
        {
          isDisplayed: this.isPanelDisplayed('results-panel'),
          key: 'results',
          component: ResultsPanel,
          label: this.$t('results'),
          icon: 'fas fa-poll',
        },
        {
          isDisplayed: this.isPanelDisplayed('info'),
          key: 'info',
          component: InformationPanel,
          label: this.$t('information'),
          icon: 'fas fa-info',
        },
        {
          isDisplayed: this.isPanelDisplayed('digital-zoom'),
          key: 'digital-zoom',
          component: DigitalZoom,
          label: this.$t('digital-zoom'),
          icon: 'fas fa-search',
        },
        {
          isDisplayed: this.isPanelDisplayed('link') && nbImages > 1,
          key: 'link',
          component: LinkPanel,
          label: this.$t('link'),
          icon: 'fas fa-link',
        },
        {
          isDisplayed: this.isPanelDisplayed('color-manipulation'),
          key: 'color-manipulation',
          component: ColorManipulation,
          label: this.$t('colors'),
          icon: 'fas fa-adjust',
        },
        {
          isDisplayed: this.isPanelDisplayed('image-layers'),
          key: 'image-layers',
          component: LayersPanel,
          label: this.$t('layers'),
          icon: 'fas fa-copy',
        },
        {
          isDisplayed: this.isPanelDisplayed('ontology'),
          key: 'ontology',
          component: OntologyPanel,
          label: this.$t('ontology'),
          icon: 'fas fa-hashtag',
          params: {
            defaultTermIds: this.defaultTermIds,
          },
        },
        {
          isDisplayed: this.isPanelDisplayed('property'),
          key: 'property',
          component: PropertiesPanel,
          label: this.$t('property'),
          icon: 'fas fa-tag',
        },
        // {
        //   isDisplayed: this.isPanelDisplayed('follow'),
        //   key: 'follow',
        //   component: FollowPanel,
        //   label: this.$t('layers'),
        //   icon: 'fas fa-street-view',
        // },
        {
          isDisplayed: this.isPanelDisplayed('review') && canEdit,
          key: 'review',
          component: ReviewPanel,
          label: this.$t('review'),
          icon: 'fas fa-check-circle',
        },
        {
          isDisplayed: this.currentUser.admin,
          key: 'heatmap',
          component: HeatmapPanel,
          label: this.$t('heatmap'),
          isActive: this.heatmapMode,
          activeClass: 'background-orange',
          icon: 'fas fa-fire',
        },
        {
          isDisplayed: this.currentUser.admin,
          key: 'clustering',
          component: ClusteringPanel,
          label: this.$t('clustering'),
          isActive: !this.clustering,
          activeClass: 'background-red',
          icon: 'fas fa-virus-slash',
        },
      ];
      return panels.filter((panel) => panel.isDisplayed);
    },
    activePanel() {
      return this.imageWrapper.activePanel;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
    activeEditTool() {
      return this.imageWrapper.draw.activeEditTool;
    },
    initialZoom() {
      return this.findInitialZoom();
    },
    maxZoom() {
      return this.$store.getters[this.imageModule + 'maxZoom'];
    },

    center: {
      get() {
        return this.imageWrapper.view.center;
      },
      set(value) {
        this.$store.dispatch(this.viewerModule + 'setCenter', {
          index: this.index,
          center: value,
        });
      },
    },
    zoom: {
      get() {
        return this.imageWrapper.view.zoom;
      },
      set(value) {
        this.$store.dispatch(this.viewerModule + 'setZoom', {
          index: this.index,
          zoom: Number(value),
        });
      },
    },
    rotation: {
      get() {
        return this.imageWrapper.view.rotation;
      },
      set(value) {
        this.$store.dispatch(this.viewerModule + 'setRotation', {
          index: this.index,
          rotation: Number(value),
        });
      },
    },

    viewState() {
      return {
        center: this.center,
        zoom: this.zoom,
        rotation: this.rotation,
      };
    },

    extent() {
      return [0, 0, this.image.width, this.image.height];
    },
    imageSize() {
      return [this.image.width, this.image.height];
    },

    baseLayerURLs() {
      const filterPrefix = this.imageWrapper.colors.filter || '';
      const params = `&tileGroup={TileGroup}&x={x}&y={y}&z={z}&channels=0&layer=0&timeframe=0&mimeType=${this.image.mime}&${this.channels}&rot=${this.mirroring}`;
      return this.image.imageServerURLs.map(
        (url) => filterPrefix + url + params
      );
    },

    colorManipulationOn() {
      return (
        this.imageWrapper.colors.brightness !== 0 ||
        this.imageWrapper.colors.contrast !== 0 ||
        this.imageWrapper.colors.hue !== 0 ||
        this.imageWrapper.colors.saturation !== 0
      );
    },
    operation() {
      return operation;
    },
    lib() {
      return {
        ...constLib,
        brightness: this.imageWrapper.colors.brightness,
        contrast: this.imageWrapper.colors.contrast,
        saturation: this.imageWrapper.colors.saturation,
        hue: this.imageWrapper.colors.hue,
      };
    },

    layersToPreload() {
      const layers = [];
      if (this.routedAnnotation) {
        layers.push(
          this.routedAnnotation.type === AnnotationType.REVIEWED
            ? -1
            : this.routedAnnotation.user
        );
      }
      if (this.routedAction === 'review' && !layers.includes(-1)) {
        layers.push(-1);
      }
      return layers;
    },

    overviewCollapsed() {
      return this.overview
        ? this.overview.getCollapsed()
        : this.imageWrapper.view.overviewCollapsed;
    },

    correction() {
      return ['correct-add', 'correct-remove'].includes(this.activeEditTool);
    },

    activeBrushInteraction() {
      return this.activeTool === 'brush';
    },
    activeRectangleInteraction() {
      return this.activeTool === 'rectangle' && this.useCustomRectangleSize;
    },
    activeSelectInteraction() {
      return this.activeTool === 'select' || this.activeTool === 'multi-select';
    },
    activeDrawInteraction() {
      return (
        (!this.activeSelectInteraction &&
          !this.activeBrushInteraction &&
          !this.activeRectangleInteraction) ||
        this.correction
      );
    },
    activeModifyInteraction() {
      return (
        this.activeSelectInteraction && this.activeEditTool && !this.correction
      );
    },
    totalImages() {
      return this.images.length;
    },
    imageIndex() {
      return this.images.find((a) => a.id === this.image.id).imageIndex;
    },
  },
  watch: {
    viewState() {
      this.savePosition();
    },
    overviewCollapsed(value) {
      this.$store.commit(this.imageModule + 'setOverviewCollapsed', value);
    },
    // Disable panning when brush tool is selected. Re-enable when de-selected.
    activeTool(value) {
      if (this.$refs.map.$map) {
        const dragPanInteraction = this.$refs.map.$map.getInteractions()
          .array_[2];
        if (
          value === 'brush' &&
          dragPanInteraction &&
          dragPanInteraction instanceof DragPan
        ) {
          dragPanInteraction.setActive(false);
        } else {
          dragPanInteraction.setActive(true);
        }
      }
    },
    annotationCounts(newCounts) {
      if (
        newCounts &&
        newCounts.loaded < newCounts.total &&
        newCounts.loaded === MAX_ANNOTATIONS_TO_LOAD
      ) {
        // if user hasn't opted out of these notification, show the clustering panel
        if (
          !localStorage.getItem(
            'silence-clustering-notification-' + this.projectId
          )
        ) {
          this.$notify({
            type: 'warn',
            text:
              this.$t('annotations-loaded-count', {
                loaded: newCounts.loaded,
                total: newCounts.total,
              }) +
              ` ${this.$t('load-all-annotations-using-button')}` +
              '<br />' +
              `<a id="silence-notification-btn" onclick='localStorage.setItem("silence-clustering-notification-${
                this.projectId
              }", true);document.getElementById("silence-notification-btn").style.display = "none";'>${this.$t(
                'dont-show-again'
              )}</a>`,
            duration: 15000,
          });
        }
      }
    },
  },
  async created() {
    if (!getProj(this.projectionName)) {
      // if image opened for the first time
      const projection = createProj({
        code: this.projectionName,
        units: 'pixels',
        extent: this.extent,
      });
      addProj(projection);
      addOlProj(projection);
    }
    this.viewerWidth = this.extent[2] - this.extent[0];

    const [existingColorMaps] = await Promise.all([
      noteApi.get(`napi/project/${this.projectId}/colormaps`),
      this.$store.dispatch(this.imageModule + 'getDefaultChannels', this.image),
    ]);

    if (!existingColorMaps || existingColorMaps.length === 0) {
      this.$store.commit(this.imageModule + 'setActiveGroup', {
        ...this.imageWrapper.channels.defaultGroup,
      });
    } else {
      this.$store.commit(this.imageModule + 'setCanManipulate', true);
      const mappedRes = existingColorMaps.map((channelGroup) => {
        return {
          ...channelGroup,
          colorMaps: channelGroup.colorMaps.map((channel) => {
            return {
              ...channel,
              id: channel.columnIndex,
              name: channel.columnName,
              color: {
                r: channel.r,
                g: channel.g,
                b: channel.b,
              },
            };
          }),
        };
      });
      this.$store.commit(this.imageModule + 'setChannelGroups', mappedRes);

      // default to most recently created group by current user
      // otherwise use default
      const groupsCreatedByUser = mappedRes.filter(
        (a) => a.createdBy === this.currentUser.id
      );
      if (groupsCreatedByUser && groupsCreatedByUser.length > 0) {
        groupsCreatedByUser.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
        this.$store.commit(
          this.imageModule + 'setActiveGroup',
          groupsCreatedByUser[0]
        );
      } else {
        this.$store.commit(this.imageModule + 'setActiveGroup', {
          ...this.imageWrapper.channels.defaultGroup,
        });
      }
    }

    if (this.routedAction === 'review') {
      this.togglePanel('review');
      if (!this.image.inReview) {
        try {
          const clone = await this.image.clone().review();
          this.$store.commit(this.imageModule + 'setImageInstance', clone);
        } catch (error) {
          console.log(error);
          this.$notify({
            type: 'error',
            text: this.$t('notif-error-start-review'),
          });
        }
      }
      this.$store.commit(this.imageModule + 'setReviewMode', true);
    }

    // remove all selected features in order to reselect them when they will be added to the map (otherwise,
    // issue with the select interaction)
    this.selectedLayers.forEach((layer) => {
      this.$store.commit(this.imageModule + 'removeLayerFromSelectedFeatures', {
        layer,
        cache: true,
      });
    });

    const idRoutedAnnot = this.$route.params.idAnnotation;
    if (idRoutedAnnot) {
      try {
        const annot = await Annotation.fetch(idRoutedAnnot);
        if (annot.image === this.image.id) {
          this.routedAnnotation = annot;
          if (this.routedAction === 'comments') {
            this.$store.commit(this.imageModule + 'setShowComments', annot);
          }
          this.$store.commit(this.imageModule + 'setAnnotToSelect', annot);
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-target-annotation'),
        });
      }
    }

    try {
      await new ImageConsultation({ image: this.image.id }).save();
    } catch (error) {
      console.log(error);
      this.$notify({
        type: 'error',
        text: this.$t('notif-error-save-image-consultation'),
      });
    }

    // set default visibility for terms
    if (this.ontology && this.ontology.terms.length) {
      // fetch the terms that are visible by default
      const defaultTermIds =
        (await noteApi.get(`/napi/project/${this.projectId}/term/default`)) ||
        [];
      if (defaultTermIds?.length) {
        this.defaultTermIds = defaultTermIds;
        const hiddenTerms = this.ontology.terms.filter(
          (term) => !defaultTermIds.includes(term.id)
        );
        // one by one, turn off the visibility of the terms that are not visible by default
        for (const term of hiddenTerms) {
          this.$store.dispatch(this.imageModule + 'toggleTermVisibility', [
            this.termIndexDictionary[term.id],
            false,
          ]);
        }
      }
    }

    this.loaded = true;
  },
  async mounted() {
    this.$eventBus.$on('updateMapSize', this.updateMapSize);
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
    // InformationPanel emits boolean showModal value for showing CalibrationModal
    this.$eventBus.$on('showCalibration', (showModal) => {
      this.showCalibrationModal = showModal;
    });
    // ChannelManipulation emits boolean to show modal
    this.$eventBus.$on('showNaming', (showModal) => {
      this.showNamingModal = showModal;
    });
    this.setInitialZoom();

    const customCenterX = getCustomValue(this.$route.query.x, this.index);
    const customCenterY = getCustomValue(this.$route.query.y, this.index);
    const customMagnification = getCustomValue(
      this.$route.query.mag,
      this.index
    );
    if ((customCenterX && customCenterY) || customMagnification) {
      // IMDT-717 Used in testing to set a default x, y, and zoom
      if (customCenterX && customCenterY) {
        this.center = [customCenterX, customCenterY];
      }
      if (customMagnification) {
        this.zoom = customMagnification;
      }
    } else {
      const existingPosition = await UserPosition.fetchLastPosition(
        this.image.id,
        this.currentUser.id
      );
      if (existingPosition && existingPosition.location) {
        this.center = [existingPosition.x, existingPosition.y];
        this.rotation = existingPosition.rotation;
        this.zoom = existingPosition.zoom;
      }
    }

    if (!this.image.magnification) {
      this.$notify({
        type: 'error',
        text: this.$t('check-image-magnification'),
      });
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('updateMapSize', this.updateMapSize);
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
    this.$eventBus.$off('showCalibration', this.showCalibrationModal);
    this.$eventBus.$off('showNaming', this.showNamingModal);

    clearTimeout(this.timeoutSavePosition);
  },
  methods: {
    takeScreenshot() {
      const map = this.$refs.map.$olObject;
      const downloadLink = this.$refs.imageDownload;
      DownloadMap(map, downloadLink);
    },
    copyLink() {
      this.$emit('copyLink');
    },
    setIsLoading(value) {
      this.$store.commit(this.viewerModule + 'setIsLoading', value);
    },
    setLoadingPercent(percent, index) {
      console.log(
        'Annotation loading: ' + percent + '%' + ' | index: ' + index
      );
      this.layerLoadingPercents[index] = percent;
      this.calcLoadingPercent();
    },
    calcLoadingPercent() {
      let maxLoadingPercent = 0;
      for (const key in this.layerLoadingPercents) {
        if (this.layerLoadingPercents[key] > maxLoadingPercent) {
          maxLoadingPercent = this.layerLoadingPercents[key];
        }
      }
      this.loadingPercent = maxLoadingPercent;
    },
    calcZoom(magValue) {
      const top = magValue / this.image.magnification;
      const zoomValue = Math.log2(top) + this.image.depth;
      return zoomValue;
    },
    findInitialZoom() {
      const container = this.$refs.container;
      let mapWidth = this.image.width;
      let mapHeight = this.image.height;
      let idealZoom = this.image.depth;
      while (
        mapWidth > container.clientWidth ||
        mapHeight > container.clientHeight
      ) {
        mapWidth /= 2;
        mapHeight /= 2;
        idealZoom--;
      }
      return idealZoom;
    },
    setResolution(resolution) {
      this.$store.dispatch(this.viewerModule + 'setImageResolution', {
        idImage: this.image.id,
        resolution,
      });
      this.$eventBus.$emit('reloadAnnotations', { idImage: this.image.id }); // refresh the sources to update perimeter/area
    },
    mapChannels(channels) {
      let channelParam = 'colms=';
      channels.forEach((channel) => {
        if (channel.visible) {
          channelParam += channelParam !== 'colms=' ? ',' : '';
          channelParam += `${channel.id}-${channel.color.r}-${channel.color.g}-${channel.color.b}`;
          channelParam += channel.histEqualization ? '-1' : '-0';
          channelParam += `-${channel.min}-${channel.max}-${channel.gamma}`;
        }
      });
      if (channelParam === 'colms=') {
        return '';
      }
      return channelParam;
    },

    setInitialZoom() {
      if (this.zoom !== null) {
        return; // not the first time the viewer is opened => zoom was already initialized
      }
      this.zoom = this.findInitialZoom();
    },

    async previousImage() {
      try {
        const prev = await this.image.fetchPrevious();
        await this.$store.dispatch(this.imageModule + 'setImageInstance', prev);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('previous-image-fetch-error'),
        });
      }
    },

    async nextImage() {
      try {
        const next = await this.image.fetchNext();
        await this.$store.dispatch(this.imageModule + 'setImageInstance', next);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('next-image-fetch-error'),
        });
      }
    },

    toggleHeatmap() {
      this.$store.commit(this.imageModule + 'toggleHeatmap');
    },

    toggleClustering() {
      this.$store.commit(this.imageModule + 'toggleClustering');
    },

    handleReloadAnnotations() {
      this.setIsLoading(true);
      this.$eventBus.$emit('reloadAnnotations', {
        idImage: this.image.id,
        callback: () => {
          this.setIsLoading(false);
        },
      });
    },

    async updateMapSize() {
      await this.$nextTick();
      if (this.$refs.map) {
        this.$refs.map.updateSize();
      }
    },
    async updateKeyboardInteractions() {
      await this.$refs.map.$createPromise; // wait for ol.Map to be created

      this.$refs.map.$map.getInteractions().forEach((interaction) => {
        if (
          interaction instanceof KeyboardPan ||
          interaction instanceof KeyboardZoom
        ) {
          interaction.condition_ = (mapBrowserEvent) => {
            return (
              noModifierKeys(mapBrowserEvent) &&
              targetNotEditable(mapBrowserEvent) &&
              this.isActiveImage &&
              !mapBrowserEvent.originalEvent.target.classList.contains(
                'ql-editor'
              )
            );
          };
        }
      });
    },

    async viewMounted() {
      await this.$refs.view.$createPromise; // wait for ol.View to be created

      if (this.routedAnnotation) {
        // center view on annotation
        const annot = this.routedAnnotation;
        const geometry = new WKT().readGeometry(annot.location);
        this.$refs.view.fit(geometry, {
          padding: [10, 10, 10, 10],
          maxZoom: this.image.depth,
        });

        // HACK: center set by view.fit() is incorrect => reset it manually
        this.center =
          geometry.getType() === 'Point'
            ? geometry.getFirstCoordinate()
            : [annot.centroid.x, annot.centroid.y];
        // ---
      }

      this.savePosition();
    },

    resetToDefault() {
      const customMagnification = getCustomValue(
        this.$route.query.mag,
        this.index
      );
      if (customMagnification) {
        this.zoom = customMagnification;
      } else {
        this.zoom = this.initialZoom;
      }

      const customCenterX = getCustomValue(this.$route.query.x, this.index);
      const customCenterY = getCustomValue(this.$route.query.y, this.index);
      if (customCenterX && customCenterY) {
        this.center = [customCenterX, customCenterY];
      }
    },

    async setBaseSource() {
      await this.$refs.baseSource.$createPromise;
      this.baseSource = this.$refs.baseSource.$source;
    },

    async addOverviewMap() {
      if (!this.isPanelDisplayed('overview')) {
        return;
      }

      await this.$refs.map.$createPromise; // wait for ol.Map to be created
      await this.$refs.baseLayer.$createPromise; // wait for ol.Layer to be created
      console.log(this.$refs.baseLayer.$layer);

      this.overview = new OverviewMap({
        view: new View({ projection: this.projectionName }),
        layers: [
          new Tile({
            extent: this.extent,
            source: GetZoomifySource(
              this.projectionName,
              this.baseLayerURLs,
              this.imageSize,
              this.extent
            ),
          }),
        ],
        tipLabel: this.$t('overview'),
        target: this.$refs.overview,
        collapsed: this.imageWrapper.view.overviewCollapsed,
      });
      this.$refs.map.$map.addControl(this.overview);
    },
    saveChannelGroupToProjectAsNew(val) {
      this.setIsLoading(true);
      this.$store
        .dispatch(this.imageModule + 'saveToProjectAsNew', {
          projectId: this.projectId,
          name: val,
          userId: this.currentUser.id,
        })
        .then(() => {
          this.setIsLoading(false);
          const type = this.successfulCall ? 'success' : 'error';
          const text = this.successfulCall
            ? this.$t('notif-success-set-for-project')
            : this.$t('notif-error-set-for-project');
          this.$notify({
            type: type,
            text: text,
          });
        });
    },

    toggleOverview() {
      if (this.overview) {
        this.overview.setCollapsed(!this.imageWrapper.view.overviewCollapsed);
      }
    },

    togglePanel(panel) {
      this.$store.commit(this.imageModule + 'togglePanel', panel);
    },

    savePosition: debounce(async function() {
      if (this.$refs.view) {
        const extent = this.$refs.view.$view.calculateExtent(); // [minX, minY, maxX, maxY]
        this.viewerWidth = extent[2] - extent[0];

        try {
          await UserPosition.create({
            image: this.image.id,
            zoom: this.zoom,
            rotation: this.rotation,
            bottomLeftX: Math.round(extent[0]),
            bottomLeftY: Math.round(extent[1]),
            bottomRightX: Math.round(extent[2]),
            bottomRightY: Math.round(extent[1]),
            topLeftX: Math.round(extent[0]),
            topLeftY: Math.round(extent[3]),
            topRightX: Math.round(extent[2]),
            topRightY: Math.round(extent[3]),
            broadcast: this.imageWrapper.tracking.broadcast,
          });
        } catch (error) {
          console.log(error);
          this.$notify({
            type: 'error',
            text: this.$t('notif-error-save-user-position'),
          });
        }

        clearTimeout(this.timeoutSavePosition);
        this.timeoutSavePosition = setTimeout(
          this.savePosition,
          constants.SAVE_POSITION_IN_IMAGE_INTERVAL
        );
      }
    }, 500),

    isPanelDisplayed(panel) {
      return (
        this.currentUser.admin || this.configUI[`project-explore-${panel}`]
      );
    },

    getTagContent(panelKey) {
      switch (panelKey) {
        case 'ontology': {
          if (!this.viewerTerms) return null;
          const visibleTerms = this.viewerTerms.filter((term) => term.visible);
          return (
            visibleTerms.length != this.viewerTerms.length &&
            visibleTerms.length
          );
        }
        case 'clustering': {
          if (
            this.annotationCounts &&
            this.annotationCounts.loaded < this.annotationCounts.total
          )
            return `!`;
        }
        default:
          return null;
      }
    },

    shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }

      switch (key) {
        case 'toggle-channels':
          if (this.isPanelDisplayed('channel-manipulation')) {
            this.togglePanel('channels');
          }
          return;
        case 'toggle-information':
          if (this.isPanelDisplayed('info')) {
            this.togglePanel('info');
          }
          return;
        case 'toggle-zoom':
          if (this.isPanelDisplayed('digital-zoom')) {
            this.togglePanel('digital-zoom');
          }
          return;
        case 'toggle-link':
          if (this.isPanelDisplayed('link') && this.nbImages > 1) {
            this.togglePanel('link');
          }
          return;
        case 'toggle-filters':
          if (this.isPanelDisplayed('color-manipulation')) {
            this.togglePanel('colors');
          }
          return;
        case 'toggle-layers':
          if (this.isPanelDisplayed('image-layers')) {
            this.togglePanel('layers');
          }
          return;
        case 'toggle-ontology':
          if (
            this.isPanelDisplayed('ontology') &&
            this.terms &&
            this.terms.length > 0
          ) {
            this.togglePanel('ontology');
          }
          return;
        case 'toggle-properties':
          if (this.isPanelDisplayed('property')) {
            this.togglePanel('properties');
          }
          return;
        // case 'toggle-broadcast':
        //   if (this.isPanelDisplayed('follow')) {
        //     this.togglePanel('follow');
        //   }
        //   return;
        case 'toggle-review':
          if (this.isPanelDisplayed('review') && this.canEdit) {
            this.togglePanel('review');
          }
          return;
        case 'toggle-overview':
          if (this.isPanelDisplayed('overview')) {
            this.toggleOverview();
          }
          return;
        case 'toggle-heatmap':
          if (this.isPanelDisplayed('heatmap')) {
            this.togglePanel('heatmap');
          }
          return;
      }
    },
  },
};
</script>

<style lang="scss">
.bottom-7em {
  bottom: 7em;
}
.panel-options {
  bottom: -1.75em;
  min-height: 10em;
  max-height: 90vh;
}

.panels li:nth-child(-n + 7) .panel-options {
  bottom: -7.5em;
  min-height: 13em;
}

.panels li:nth-child(-n + 3) .panel-options {
  top: -1.75em;
  bottom: auto;
  min-height: 7.5em;
}

.panels li:nth-child(4) .panel-options {
  top: -5.5em;
  bottom: auto;
}

#image-navigation-buttons {
  position: absolute;
  top: 0.75rem;
  right: 3.5rem;
  z-index: 100;
  font-size: 0.8em;
  background-color: transparent;

  .image-index {
    position: relative;
    top: 4px;
  }

  button {
    display: inline-block;
  }
}

.updating-background {
  opacity: 0.5;
}

.mag-btn:focus {
  z-index: 101; // Show full button border when selected
}

.mag-btn span {
  line-height: revert;
}

.background-orange {
  background-color: orange;
}

.background-red {
  background-color: rgba(255, 0, 0, 0.445);
}

/* ----- CUSTOM STYLE FOR OL CONTROLS ----- */

.ol-zoom {
  top: calc(0.75rem + 1px);
}

.ol-zoom,
.ol-rotate {
  background: none !important;
}

.ol-rotate:not(.custom) {
  display: none;
}

.ol-control {
  padding-top: 0px;
}

.ol-control button {
  background: white !important;
  color: black !important;
  border-radius: 2px !important;
  border: 1px solid $grey-lighter;
  height: 1.7rem;
  width: 1.775rem;
  margin: -1px 0px 0px 0px;

  &:hover {
    box-shadow: 0 0 1px black;
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.ol-zoom-in {
  margin-bottom: 2px !important;
}

.snapshot-container {
  top: 15em;
}

.copy-link-container {
  top: 17em;
}

.custom-overview {
  background: rgba(255, 255, 255, 0.8);

  .ol-overviewmap {
    position: static;
    background: none;
  }

  .ol-overviewmap:not(.ol-collapsed) button {
    bottom: 2px !important;
  }
}
</style>
