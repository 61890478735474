import { createColorStyle, createLineStyle } from '@/utils/style-utils.js';

const initialTermsOpacity = 1;
const initialGlobalOpacity = 0.5;
const initialPointSize = 1;

const defaultFillColor = '#fff';
const defaultStrokeColor = '#000';
const defaultStrokeWidth = 2;

const defaultMultiTermFillColor = '#fff';
const defaultMultiTermStrokeColor = '#000';
const defaultMultiTermStrokeWidth = 2;

export default {
  state() {
    return {
      terms: null,

      multiTermFillColor: defaultMultiTermFillColor,
      multiTermStrokeColor: defaultMultiTermStrokeColor,
      multiTermStrokeWidth: defaultMultiTermStrokeWidth,

      defaultFillColor: defaultFillColor,
      defaultStrokeColor: defaultStrokeColor,
      defaultStrokeWidth: defaultStrokeWidth,

      displayNoTerm: true,
      noTermOpacity: initialTermsOpacity,

      defaultStyle: createColorStyle(
        defaultFillColor, // fillColor
        defaultStrokeColor, // strokeColor
        defaultStrokeWidth, // strokeWidth
        initialGlobalOpacity,
        initialPointSize
      ),

      globalOpacity: initialGlobalOpacity,
      defaultPointSize: initialPointSize,
    };
  },

  mutations: {
    addTerm(state, term) {
      state.terms.push(
        formatTerm(term, state.globalOpacity, state.defaultPointSize)
      );
    },

    setTerms(state, terms) {
      state.terms = terms;
    },

    toggleTermVisibility(state, payload) {
      const [indexTerm, value] = payload;
      const term = state.terms[indexTerm];
      // if boolean value exists, use value. Otherwise set it to opposite of visible
      term.visible = value ?? !term.visible;
    },

    setDisplayNoTerm(state, value) {
      state.displayNoTerm = value;
    },

    setTermOpacity(state, { indexTerm, opacity }) {
      const term = state.terms[indexTerm];
      term.opacity = opacity;
    },

    setNoTermOpacity(state, opacity) {
      state.noTermOpacity = opacity;
    },

    resetTermOpacities(state) {
      state.noTermOpacity = initialTermsOpacity;
    },

    setGlobalOpacity(state, opacity) {
      state.globalOpacity = opacity;
    },

    setMultiTermSettings(
      state,
      { multiTermFillColor, multiTermStrokeColor, multiTermStrokeWidth }
    ) {
      state.multiTermFillColor =
        multiTermFillColor || defaultMultiTermFillColor;
      state.multiTermStrokeColor =
        multiTermStrokeColor || defaultMultiTermStrokeColor;
      state.multiTermStrokeWidth =
        multiTermStrokeWidth || defaultMultiTermStrokeWidth;
    },
  },

  actions: {
    initialize({ state, commit, rootGetters }) {
      const terms = formatTerms(
        rootGetters['currentProject/terms'],
        initialGlobalOpacity,
        state.defaultPointSize
      );
      commit('setTerms', terms);

      const ontology = rootGetters['currentProject/ontology'];
      if (ontology) {
        commit('setMultiTermSettings', {
          multiTermFillColor: ontology.multiTermFillColor,
          multiTermStrokeColor: ontology.multiTermStrokeColor,
          multiTermStrokeWidth: ontology.multiTermStrokeWidth,
        });
      }
    },

    toggleTermVisibility({ state, commit }, [indexTerm, value]) {
      commit('toggleTermVisibility', [indexTerm, value]);
      const toggledTerm = state.terms[indexTerm];
      if (!toggledTerm.visible) {
        commit('removeTermFromSelectedFeatures', {
          idTerm: toggledTerm.id,
          terms: state.terms,
        });
      }
    },

    setDisplayNoTerm({ commit }, value) {
      commit('setDisplayNoTerm', value);
      if (!value) {
        commit('removeNoTermFromSelectedFeatures');
      }
    },

    async refreshData({ state, commit, rootGetters }) {
      const terms = formatTerms(
        rootGetters['currentProject/terms'],
        state.layersOpacity,
        state.defaultPointSize,
        state.terms
      );
      commit('setTerms', terms);
    },
  },

  getters: {
    termsMapping: (state) => {
      return state.terms.reduce((mapping, term) => {
        mapping[term.id] = term;
        return mapping;
      }, {});
    },
  },
};

/**
 * @param terms
 * @param globalOpacity
 * @param pointSize
 * @param previousTerms
 */
function formatTerms(terms, globalOpacity, pointSize, previousTerms = []) {
  if (!terms) {
    return;
  }

  const result = [];
  const nbTerms = terms.length;
  for (let i = 0; i < nbTerms; i++) {
    const term = terms[i];
    const prevTerm = previousTerms.find((prevTerm) => prevTerm.id === term.id);
    result.push(formatTerm(term, globalOpacity, pointSize));
  }
  return result;
}

/**
 * @param term
 * @param globalOpacity
 * @param pointSize
 */
function formatTerm(term, globalOpacity, pointSize) {
  return {
    id: term.id,
    opacity: initialTermsOpacity,
    visible: true,
    color: term.color,
    strokeColor: term.strokeColor || defaultStrokeColor,
    strokeWidth: term.strokeWidth || defaultStrokeWidth,
    pointSize: term.pointSize || pointSize,
  };
}
